.hero{
    &__wrapper{
        text-align: center;
        margin-top: 8rem;

        h1{
            font-weight: 300;
            font-size: 2rem;
        }

        p{
            width: 60%;
            margin: 2rem auto;
            line-height: 1.4rem;
        }

        .number{
            position: absolute;
            font-size: .8rem;
            margin-top: .1rem;
            margin-left: .12rem;
        }

        .space{
            margin-left: .8rem;
        }
    }
}