.parcerias{
    &__wrapper{
        margin-top: 2rem;
        margin-bottom: 2rem;


        @include breakpoint-up(large){
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .parcerias__text{
            font-size: 0.875rem;
            text-align: left;

            @include breakpoint-up(large){
                margin-top: .8rem;
            }
        }

        img{
            width: 30%;
            margin-top: 1rem;

            @include breakpoint-up(large){
                width: 10rem;
            }
        }
    }
}