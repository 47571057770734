.info{
    &__wrapper{
        margin-top: 5rem;
        margin-bottom: 5rem;

        @include breakpoint-up(large){
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .info__content{
            order: 2;

            @include breakpoint-up(large){
                width: 45%;
            }
            h3{
                font-weight: 300;
                font-size: 1.5rem;
            }

            p{
                margin-top: 1.5rem;
                font-size: 0.9rem;
                font-weight: 300;
                line-height: 1.3rem;
            }

            .info__cta{
                margin-top: 3rem;
                margin-bottom: 2rem;

                @include breakpoint-up(large){
                    margin-bottom: 0rem;
                }
            }
        }

        .info__image{
            img{
                width: 100%;
                height: 30rem;
                object-fit: cover;
                box-shadow: 0px 0px 5px 4px rgba(0, 0, 0, 0.25);
                margin-top: 2rem;

                @include breakpoint-up(large){
                    margin-top: 0rem;
                    width: 90%;
                }
            }
        }
    }
}