.about{
    &__wrapper{
        margin-top: 8rem;

        .about__top{
            h1{
                font-weight: 300;
                font-size: 2rem;
                text-align: center;
                margin-top: 2rem;
            }
    
            p{
                font-size: 0.875rem;
                line-height: 1.5rem;
                text-align: justify;
                width: 100%;
                margin: 2rem auto;
    
                @include breakpoint-up(large){
                    width: 60%;
                }
            }
        }

        .about__grid{
            margin-top: 5rem;
            margin-bottom: 5rem;
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 2rem;

            @include breakpoint-up(medium){
                grid-template-columns: repeat(2, 1fr);
            }

            @include breakpoint-up(large){
                grid-template-columns: repeat(3, 1fr);
            }

            .box{
                text-align: center;

                img{
                    width: 100%;
                }
                
                h2{
                    margin-top: 1.5rem;
                    font-weight: 300;
                }

                p{
                    margin-top: 1rem;
                    text-align: center;
                    font-size: 0.875rem;
                    line-height: 1.4rem;

                    @include breakpoint-up(large){
                        width: 80%;
                        margin: 1rem auto;
                    }
                }
            }
        }

        .about__span{
            h1{
                text-align: center;
                color: $blue;
                margin-bottom: 5rem;
            }
        }
    }
}