.footer{
    &__wrapper{
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        text-align: center;

        @include breakpoint-up(large){
            display: flex;
            justify-content: center;
        }

        p{
            font-size: 0.875rem;

            &:not(:first-child){
                margin-top: 1rem;

                @include breakpoint-up(large){
                    margin-top: 0rem;
                }
            }

            @include breakpoint-up(large){
                margin: 0rem 1.5rem;
            }
        }
    }
}