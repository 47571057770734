.info-dispositivos{
    &__wrapper{
        margin-top: 8rem;
        margin-bottom: 4rem;

        p{
            font-size: 0.875rem;
            text-align: justify;
            line-height: 1.5rem;
        }
    }
}