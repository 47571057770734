.contact__form{
    background-color: $white-bg;

    h3{
        font-weight: 300;
        font-size: 1.5rem;
        text-align: center;
        padding-top: 3rem;
    }

    &__wrapper{
        padding-top: 3rem;
        padding-bottom: 3rem;
        width: 100%;

        @include breakpoint-up(large){
            display: flex;
            align-items: center;
            justify-content: space-between; 
        }

    .contact__info{

        &__text{
            &:not(:first-child){
                margin-top: 2rem;
            }
            
            p{
                margin-top: .3rem;
                line-height: 1.3rem;
            }
        }

        @include breakpoint-up(large){
            width: 70%;
        }
    }

    .contact__wrapper__form{
        margin-top: 2rem;

        @include breakpoint-up(large){
            margin-top: 0rem;
        }

        input, textarea{
            width: 100%;
            resize: none;
            font-size: 0.875rem;
            color: $black;
            padding: .8rem 1rem;
            background: none;
            border: 1px solid $black;

            &:not(:first-child){
                margin-top: 1rem;
            }

            @include breakpoint-up(large){
                width: 100%;
            }
        }

        .form__cta{
            margin-top: 2rem;

            input{
                letter-spacing: 0;
                width: 30%;
                cursor: pointer;
            }
        }
    }

  }
}