.hero__wrapper__right, .hero__wrapper__left{
    @include breakpoint-up(large){
        display: flex;
        justify-content: space-between;
    }

    .image-hero{
        height: 30rem;
        object-fit: cover;

        @include breakpoint-up(large){
            height: 40rem;
        }
    }

    img{
        width: 100%;
        object-fit: cover;

        @include breakpoint-up(large){
            width: 50%;
        }
    }

    .logos{
        @include breakpoint-up(large){
            width: 20rem;
        }
    }

    .hero__wrapper__content{
        margin: 2rem 0;

        h3{
            margin-top: 2rem;
            font-size: 1rem;
            font-weight: 400;
        }

        ul{
            display: flex;
            align-items: center;
            font-size: 0.875rem;

            svg{
                font-size: 1.8rem;
            }
        }

        hr{
            width: 50%;
            margin-top: 1rem;
            margin-bottom: 1rem;
        }

        .dot-left{
            margin-left: 2rem;
        }

        .email-website{
            margin: 1.5rem 0;
        }

        p{
            line-height: 1.4;
            font-size: 0.875rem;

            @include breakpoint-up(large){
                width: 80%;
            }
        }

        @include breakpoint-up(large){
            width: 50%;
            margin-left: 5rem;
        }
    }
}
.hero__wrapper__left{
    margin-top: 5rem;

    img{
        @include breakpoint-up(large){
            order: 2;
        }
    }
}

.hero-social-media{
    margin-top: 1rem;

    svg{
        font-size: 2rem;
        
        &:not(:first-child){
            margin-left: 1rem;
        }
    }
}