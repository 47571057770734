.main{
    margin-top: 3rem;
    background-color: $white-bg;
    &__wrapper{
        padding-top: 4rem;
        padding-bottom: 4rem;
        margin: auto;
        text-align: center;

        @include breakpoint-up(large){
            width: 60%;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
        }

       .content{
           margin-top: 3rem;

           &__top{
               h2{
                   font-weight: 300;
               }

               p{
                   margin: 2rem auto;
                   line-height: 1.4rem;
                   width: 80%;
               }
           }

           &__info{
               margin-top: 2rem;
               
               p{
                   font-size: 0.875rem;
               }

               .email-website{
                   margin-top: 1.5rem;
                   margin-bottom: 1.5rem;
               }
           }
       }
    }
}