.container{
    &--x{
        padding-right: 1rem;
        padding-left: 1rem;
    }
    
    @include breakpoint-up(large){
        max-width: 85rem;
        margin: 0 auto;
    }
}