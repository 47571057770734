*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 100%;
  font-weight: 400;
}

body {
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;
}

a,
a:visited,
a:hover {
  text-decoration: none;
}

ul{
    list-style: none;
}

.bold{
  font-weight: 600;
}

input, button, textarea{
  font-family: 'Montserrat', sans-serif;
}