.hero__wrapper__org__right, .hero__wrapper__org__left{
    margin-top: 0rem;
    
    @include breakpoint-up(large){
        display: flex;
    }

    img{
        width: 100%;

        @include breakpoint-up(large){
            height: 35rem;
            object-fit: cover;
            width: 50%;
        }
    }

    .hero__org__content {
        @include breakpoint-up(large){
            width: 50%;
            padding-left: 5rem;
        }

        h1{
            font-weight: 300;
            margin-top: 2rem;

            @include breakpoint-up(large){
                margin-top: 4rem;
            }
        }

        p{
            margin-top: 2rem;
            font-size: 0.9rem;
            line-height: 1.4;

            @include breakpoint-up(large){
                width: 80%;
            }
        }

        .list{
            margin-top: 2rem;

            @include breakpoint-up(large){
                display: flex;
            }

            .list-2{
                margin-top: 1rem;

                @include breakpoint-up(large){
                    margin-top: 0rem;
                    margin-left: 2rem;
                }
            }
        }
    }
}

.hero__wrapper__org__left{
    img{
        order: 2;
    }
}