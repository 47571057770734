.hero-economia{
    
    &__wrapper{
        margin-top: 4.5rem;

        img{
            width: 100%;
            height: 30rem;
            object-fit: cover;

            @include breakpoint-up(large){
                width: 50%;
                height: 40rem;
            }
        }

        .hero-economia-content{
            text-align: justify;
            margin-bottom: 2rem;

            @include breakpoint-up(large){
                width: 50%;
                margin-bottom: 0rem;
            }

            .content-title{
                margin-top: 3rem;
                text-align: center;

                @include breakpoint-up(large){
                    margin-top: 5rem;
                }
                
                h2{
                    font-size: 1.5rem;
                    font-weight: 400;
                }

                h3{
                    font-weight: 400;
                    font-size: 1.2rem;
                    margin-top: 1rem;
                }
            }

            .content-description{
                margin-top: 2rem;

                @include breakpoint-up(large){
                    width: 80%;
                    margin-top: 3rem;
                    margin-left: auto;
                    margin-right: auto;
                }
                p{
                    font-size: 0.975rem;
                    line-height: 1.4rem;
                }
            }

            hr{
                margin: 1rem 0;

                @include breakpoint-up(large){
                    width: 60%;
                    margin: 2rem auto;
                }
            }

            .content-bottom{
                text-align: center;

                p{
                    margin-top: 1rem;
                }
            }
        }
    }
}

.hero-economia-right, .hero-economia-left{
    @include breakpoint-up(large){
        display: flex;
    }
}

.hero-economia-left{
    img{
        order: 2;
    }

    .content-description{
        p{
            @include breakpoint-up(large){
                width: 60%;
                margin-right: auto;
                margin-left: auto;
            }
        }
    }
}

.small-img{
    @include breakpoint-up(large){
        height: 30rem !important;
    }
}

.small-width{
    @include breakpoint-up(large){
        width: 60%;
        margin-right: auto;
        margin-left: auto;
    }
}