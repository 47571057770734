.flex{
    display: flex;

    &-jc-sb{
        justify-content: space-between;
    }

    &-jc-c{
        justify-content: center;
    }

    &-ai-c{
        align-items: center;
    }

    &-fd-c{
        flex-direction: column;
    }
}