.hide-for-desktop{
    @include breakpoint-up(large){
        display: none !important;
    }    
}

.hide-for-mobile{
    @include breakpoint-down(medium){
        display: none !important;
    }    
}