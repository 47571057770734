.btn-white, .btn-black{
    color: $white;
    font-size: 0.875rem;
    letter-spacing: 0.155em;
    border: 1px solid $white;
    padding: .7rem 1.2rem;
    font-weight: 400;
}

.btn-black{
    color: $black;
    border: 1px solid $black;
}