.header{
    position: fixed; 
    top: 0;
    width: 100%; 
    z-index: 999;
    background-color: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

    .overlay{
        display: block;
        opacity: 1;
        position: fixed;
        z-index: 998;
        top: 4.6rem;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-image: linear-gradient(#222, transparent);
    }

    .nav{
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;

        &__logo{
            a{
                color: $black;

                span{
                    font-weight: 400;
                }
            }
        }

        &__icon{
            svg{
                font-size: 1.3rem;
            }
        }

        &__menu{

            li{
                @include breakpoint-up(large){
                    &:not(:last-child){
                        margin-right: 2rem;
                    }
                }
            }

            a{
                color: $black;
                font-size: 0.875rem;
                transition: color .2s ease-in-out;

                &:hover{
                    color: $blue;
                    transition: color .2s ease-in-out;
                }
            }

            .nav-active{
                color: $blue;
            }
        }
    }

    .active{
        position: absolute;
        width: 90%;
        left: 50%;
        transform: translateX(-50%);
        background-color: $white;
        top: 6rem;  
        z-index: 999;
        border-radius: 3px;
        text-align: center;

        li{
            margin: 1.875rem 0px;
        }
    }

}
