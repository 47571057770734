.swiper-container {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 1rem;
    background: #fff;

    .swiper-content{
        position: absolute;

        h1{
            color: $white;
            font-weight: 300;
            font-size: 1.3rem;
            margin-bottom: 2rem;

            
            @include breakpoint-up(medium){
              font-size: 2rem;
          }

            @include breakpoint-up(large){
                font-size: 4rem;
            }
        }
    }
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100vh !important;
    object-fit: cover;
  }
  
  .swiper-button-prev::after, .swiper-button-next::after{
    color: $white !important;
    font-size: 1.5rem !important;
  }

  .swiper-pagination-bullet{
    background: $white !important;
  }